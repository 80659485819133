const config = {
  envName: "dev",
  firebaseConfig: {
    apiKey: "AIzaSyDyl_F1fTqCsSfPWxZsOe-fXd_8ZoCO_vc",
    authDomain: "tekutekulife-campaign-dev.firebaseapp.com",
    projectId: "tekutekulife-campaign-dev",
    storageBucket: "tekutekulife-campaign-dev.appspot.com",
    messagingSenderId: "1077468942058",
    appId: "1:1077468942058:web:77a4019f1bc0dccb5b5193",
    measurementId: "G-YF4P7J3DXY"
  }
};

export default config;
